import React, { useEffect, useState } from "react";
import { Gallery1, Gallery2, Gallery3, Gallery4, GalleryBanner } from "../../Assets.tsx";
// import { ReactTyped } from "react-typed";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Gallery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useGSAP(() => {
        if (!isMobile) {
            gsap.from(".gsapGallerySection h1", {
                opacity: 0,
                duration: 0.5,
                x: 500,
                scrollTrigger: {
                    trigger: ".gsapGallerySection h1",
                    scroller: "body",
                    markers: false,
                    start: "top 40%",
                },
            });
        }
    });

    // useGSAP(() => {
    //     if (!isMobile) {
    //         gsap.from(".gsapGallerySection .GalleryImage", {
    //             opacity: 0,
    //             duration: 0.5,
    //             scale: 0.5,
    //             y: 20,
    //             delay: 0,
    //             scrollTrigger: {
    //                 trigger: ".gsapGallerySection .GalleryImage",
    //                 scroller: "body",
    //                 markers: false,
    //                 start: "top 40%",
    //             },
    //         });
    //     }
    // });

    // useGSAP(() => {
    //     if (!isMobile) {
    //         gsap.from(".gsapGallerySection .Gallerysection", {
    //             opacity: 0,
    //             duration: 0.5,
    //             scale: 0.5,
    //             y: 20,
    //             delay: 0,
    //             scrollTrigger: {
    //                 trigger: ".gsapGallerySection .Gallerysection",
    //                 scroller: "body",
    //                 markers: false,
    //                 start: "top 40%",
    //             },
    //         });
    //     }
    // });
  return (
    <div id="Gallery" className="gsapGallerySection GalleryImages" style={{height:"700px"}}>
    <div className="fixed-bg-GalleryPage">
    <h1
        style={{
          fontSize: "45px",
          color: "#A87C36",
          fontWeight: 300,
          textAlign: "center",
          marginBottom: "30px"
        }}
      >
         Gallery
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="GalleryContainer Gallerysection"
      >
          <div className="image-div GalleryImage">
          <a href="https://www.instagram.com/p/CCQoz9XHTJ2/" target="_blank">
            <img
              src={GalleryBanner}
              style={{
                height: "500px",
                width: "100%",
                cursor: "pointer",
                border: "2px solid white",
                borderRadius: "5px",
              }}
              className=" GalleryImage GalleryImages"
            />
          <div className="hoverNameText">Click to View</div>
          </a>
        </div>
        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            flexDirection: "column",
          }}
          className="CarSection"
        >
          <div className="image-div GalleryImage">
            <a
              href="https://www.instagram.com/p/CCY1tWqHvv-/"
              target="_blank"
            >
              <img
                src={Gallery1}
                style={{
                  width: "300px",
                  height:"290px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className=" GalleryImage GalleryImages"
              />
            <div className="hoverNameText">Click to View</div>
            </a>
          </div>
          <div className="image-div GalleryImage">
            <a
              href="https://www.instagram.com/p/CCoYRHOncWa/"
              target="_blank"
            >
              <img
                src={Gallery2}
                style={{
                    width: "300px",
                    height:"200px",
                  marginTop: "5px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className=" GalleryImage GalleryImages"
              />
            <div className="hoverNameText">Click to View</div>
            </a>
          </div>
        </div>
        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            flexDirection: "column",
          }}
          className="CarSection"
        >
          <div className="image-div GalleryImage">
          <a
              href="https://www.instagram.com/p/B5XbYCeJKzq/"
              target="_blank"
            >
              <img
                src={Gallery3}
                style={{
                  width: "300px",
                  height:"200px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className=" GalleryImage GalleryImages"
              />
            <div className="hoverNameText">Click to View</div>
            </a>
          </div>

          <div className="image-div GalleryImage">
            <a
              href="https://www.instagram.com/p/B5NqHPDpHC3/"
              target="_blank"
            >
              <img
                src={Gallery4}
                style={{
                  width: "300px",
                  height:"290px",
                  marginTop: "5px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className="CarImageSize GalleryImage GalleryImages"
              />
            <div className="hoverNameText">Click to View</div>
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
   <a href="https://www.instagram.com/kalpataru_interiors/" target="_blank">

        <div className="buttons GalleryImage">
  <button className="blob-btn">
  View More
  <span className="blob-btn__inner">
      <span className="blob-btn__blobs">
        <span className="blob-btn__blob"></span>
        <span className="blob-btn__blob"></span>
        <span className="blob-btn__blob"></span>
        <span className="blob-btn__blob"></span>
      </span>
    </span>
  </button>
  <br/>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
      <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
    </filter>
  </defs>
</svg>
      </div>
      </a>
    </div>
    </div>
    </div>
  );
};

export default Gallery;
