import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "../Assets.tsx";
import "./Navbar.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const NavBar = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  useGSAP(() => {
    gsap.from(".nav-logo", {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".nav-item", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.3,
      delay: 1,
    });
  });

  const handleClick = () => setClick(!click);
  const handleDropdownClick = () => setDropdown(!dropdown);
  const Close = () => {
    setClick(false);
    setDropdown(false);
  };
  
  const handleContactClick = () => {
    window.location.href = "tel:7700096022";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto: kalpataruinteriors2229@gmail.com";
  };
  
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/917700096022", "_blank");
  };

  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img
              src={Logo}
              alt="logo"
              style={{
                width: "112px",
                cursor: "pointer",
                marginTop: "2px",
                borderRadius: "5px"
              }}
            />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <a
              href="/"
              onClick={click ? handleClick : () => {}}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="nav-item nav-links"> Home </li>{" "}
            </a>
            <a
              href="#services"
              onClick={click ? handleClick : () => {}}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="nav-item nav-links"> Services </li>{" "}
            </a>
            <a
              href="#Process"
              onClick={click ? handleClick : () => {}}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="nav-item nav-links"> Our Process</li>{" "}
            </a>
            <a
              href="#Clients"
              onClick={click ? handleClick : () => {}}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="nav-item nav-links"> Clients </li>{" "}
            </a>
            <li
              className="nav-item nav-links dropdown"
              onClick={handleDropdownClick}
            >
              Contact Us
              <div
                className={
                  dropdown
                    ? "dropdown-content dropdown-content-show"
                    : "dropdown-content"
                }
              >
                <a onClick={handleContactClick}>Call Us</a>
                <a onClick={handleWhatsAppClick}>Whatsapp Us</a>
                <a onClick={handleEmailClick}>Email Us</a>
              </div>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
