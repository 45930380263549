import React, { useEffect, useState } from "react";
import "./HistoryCarousel.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export interface CarouselProps {
  members: {
    image: string;
    name: string;
  }[];
}

const HistoryCarousel: React.FC<CarouselProps> = ({ members }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
    gsap.from(".ourClientsGSAP h1", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".ourClientsGSAP h1",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % members.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + members.length) % members.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, []);

  const currentSlides = members.slice(currentIndex, currentIndex + 3);
  const displayedSlides =
    currentSlides.length < 3
      ? currentSlides.concat(members.slice(0, 3 - currentSlides.length))
      : currentSlides;

  const currentSlidesMobile = members.slice(currentIndex, currentIndex + 1);
  const displayedSlidesMobile =
    currentSlidesMobile.length < 1
      ? currentSlidesMobile.concat(
          members.slice(0, 1 - currentSlidesMobile.length)
        )
      : currentSlidesMobile;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      nextSlide();
    }, 8000);

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div id="Clients" className="carousel10 bgImage">
      <div className="ourClientsGSAP">
        <h1
          style={{
            textAlign: "center",
            textTransform: "capitalize",
            marginTop: "15px",
            fontSize: "45px",
            color: "#A87c36",
            marginBottom: "35px",
            fontWeight: 300,
          }}
          className="rooms-suits-header"
        >
          <span style={{ color: "#1b1b1b" }}>Our</span> Clients
        </h1>
      </div>

      <div className="desktopView">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="arrowHover1"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginTop: "5px",
              animation: "move 0.5s infinite alternate"
            }}
            className="scroll-arrow"
            id="scroll-left"
            onClick={prevSlide}
          >
            &#8592;
          </div>
        </div>
        {displayedSlides.map((member, index) => (
          <div
            className={`slide10 ${index === 1 ? "active10" : "inactive10"}`}
            key={index}
          >
            {index === 1 ? (
              <div className="color-wrapper">
                <div className="bg-shape"></div>
                <img
                  src={member.image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    borderRadius: "8px",
                    border: "5px solid white",
                    padding: "0px",
                    filter: "grayscale(0%)",
                    display: "inline",
                  }}
                  className={`image10 color10`}
                />
                <h3
                  style={{
                    color: "#ffa500",
                    position: "relative",
                    fontSize: "35px",
                    fontWeight: 900,
                    marginTop: "30px"
                  }}
                >
                  {member.name}
                </h3>
                
              </div>
            ) : (
              <>
                <img
                  src={member.image}
                  alt={`Slide ${index + 1}`}
                  className={`image10 bw10`}
                />
                <div className="member-info10 bw10">
                  <h3
                    style={{
                      color: "#ffa500",
                      fontSize: "30px",
                      fontWeight: 900,
                    }}
                  >
                    {member.name}
                  </h3>
                  
                </div>
              </>
            )}
          </div>
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="arrowHover1"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginTop: "5px",
              animation: "move 0.5s infinite alternate"
            }}
            className="scroll-arrow"
            id="scroll-right"
            onClick={nextSlide}
          >
            &#8594;
          </div>
        </div>
      </div>

      <div className="MobileView">
        <button className="prev10" onClick={prevSlide}>
          ❮
        </button>
        {displayedSlidesMobile.map((member, index) => (
          <div
            className="slide"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            key={index}
          >
            <img
              src={member.image}
              style={{ width: "90%", height: "auto" }}
              alt={`Slide ${index + 1}`}
              className="image"
            />
            <div className="member-info" style={{ textAlign: "center" }}>
              <h3 style={{ color: "#daa520" }}>{member.name}</h3>
            </div>
          </div>
        ))}
        <button className="next10" onClick={nextSlide}>
          ❯
        </button>
      </div>
    </div>
  );
};

export default HistoryCarousel;
