import React from "react";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css"; 

interface CarouselProps {
  images: string[];
  text?: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images, text }) => {
  return (
    <ResponsiveCarousel showArrows={true} autoPlay={true} infiniteLoop={true}>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Carousel Image ${index + 1}`} />
          {text && <p className="legend">{text[index]}</p>}
        </div>
      ))}
    </ResponsiveCarousel>
  );
};

export default Carousel;
