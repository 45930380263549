import React, { useEffect, useState } from "react";
import "./Card.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Cards = ({ cards }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
    gsap.fromTo(
      ".image-card img",
      { opacity: 0, y: -100 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power2.out",
        stagger: 0.3,
        scrollTrigger: {
          trigger: ".image-card img",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      }
    );
    gsap.from(".titleTextGSAP", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".titleTextGSAP",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  return (
    <div id="Process">
    <h1
        style={{
          fontSize: "45px",
          color: "#A87C36",
          fontWeight: 300,
          textAlign: "center",
          marginBottom: "20px",
        }}
        className="titleTextGSAP"
      >
        Interior Design & Execution Process
      </h1>
    <div id= "Event" className="image-card-container">
      {cards.map((card, index) => (
        <div key={index} className="image-card">
          <img
            src={card.image}
            alt={card.heading}
            className="image-card__image"
          />
          <div className="image-card__content">
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <span
                className="image-card__number"
                style={{ marginRight: "10px" }}
              >
                {card.number}
              </span>
              <h2 className="image-card__heading">{card.heading}</h2>
            </div>
            <p className="image-card__description">{card.description}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default Cards;
