export const Logo = require("./Assets/Logo1.jpg");
export const FooterImg = require("./Assets/FooterImg.jpg");
export const Banner = require("./Assets/Home3.JPG");
export const BannerVideo = require("../src/Assets/Trim1.mp4");
export const BannerVideo2 = require("../src/Assets/Trim2.mp4");
export const Apartment1 = require("../src/Assets/Home1.JPG");
export const Apartment2 = require("../src/Assets/Home4.JPG");
export const Banner2 = require("../src/Assets/Home2.JPG");
export const Apartment3 = require("../src/Assets/Home3.JPG");
export const Banner3 = require("../src/Assets/Office1.JPG");
export const Apartment4 = require("../src/Assets/Home2.JPG");
export const Apartment5 = require("../src/Assets/Home5.JPG");
export const Banner5 = require("../src/Assets/Office2.JPG");
export const Rooms1 = require("../src/Assets/Office1.JPG");
export const Rooms2 = require("../src/Assets/Office2.JPG");
export const Rooms3 = require("../src/Assets/Office3.JPG");
export const Rooms4 = require("../src/Assets/Office4.JPG");
export const leaf1 = require("./Assets/leaf1.jpg");
export const leaf2 = require("./Assets/leaf2.jpg");
export const leaf3 = require("./Assets/leaf3.jpg");
export const leaf4 = require("./Assets/leaf4.jpg");

export const service1 = require("../src/Assets/service1.jpg");
export const service2 = require("../src/Assets/service2.jpg");
export const service3 = require("../src/Assets/service3.jpg");
export const service4 = require("../src/Assets/service4.jpg");
export const service5 = require("../src/Assets/service5.jpg");
export const service6 = require("../src/Assets/service6.jpg");
export const service7 = require("../src/Assets/service7.jpg");

export const GalleryBanner = require("../src/Assets/Home1.JPG");
export const Gallery1 = require("../src/Assets/Gallery1.jpg");
export const Gallery2 = require("../src/Assets/Gallery2.jpg");
export const Gallery3 = require("../src/Assets/Gallery3.jpg");
export const Gallery4 = require("../src/Assets/Gallery4.jpg");


export const residentialImages = [
  { 
    src: require("./Assets/Recidencial.jpg"), 
    alt: "Residential Image 1", 
    info: "Information about Residential Image 1" 
  },
  { 
    src: require("./Assets/Commercial.jpg"), 
    alt: "Commercial Image 2", 
    info: "Information about Residential Image 2" 
  },
];

export const commercialImages = [
  { 
    src: require("./Assets/Commercial.jpg"), 
    alt: "Commercial Image 1", 
    info: "Information about Commercial Image 1" 
  },
  { 
    src: require("./Assets/Recidencial.jpg"), 
    alt: "Residencial Image 2", 
    info: "Information about Commercial Image 2" 
  },
];
