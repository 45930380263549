import React from "react";
import { Logo, FooterImg, leaf1, leaf2, leaf3, leaf4 } from "../Assets.tsx";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useHistory } from "react-router-dom";
import "./Footer.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const history = useHistory();

  useGSAP(() => {
    gsap.from(".gsapFooterLogo", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapFooterLogo",
        scroller: "body",
        markers: false,
        start: "top 55%",
      },
    });
    gsap.from(".FooterSection", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.5,
      delay: 1,
      scrollTrigger: {
        trigger: ".FooterSection",
        scroller: "body",
        markers: false,
        start: "top 80%",
      },
    });
  });

  const handleLinkClick = (path: string) => {
    history.push(path);
  };

  const handleContactClick = () => {
    window.location.href = "tel:7700096022";
  };

  const handleContactClick2 = () => {
    window.location.href = "tel:8087678869";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto: kalpataruinteriors2229@gmail.com";
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/917700096022", "_blank");
  };

  const leafImages = [leaf1, leaf2, leaf3, leaf4];

  return (
    <div
      style={{
        height: "100vh",
        background: `url(${FooterImg}) no-repeat center center/cover`,
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px 20px",
        position: "relative",
        overflow: "hidden",
        borderTop: "5px solid black",
      }}
      className="footerResize"
    >
      {leafImages.map((leaf, index) => (
        <img src={leaf} alt="leaf" className="leaf" key={index} />
      ))}
      <img
        src={Logo}
        alt="icon"
        style={{
          width: "181px",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push("/");
        }}
        className="gsapFooterLogo"
      />
      <span
        style={{
          fontSize: "26px",
          fontWeight: 800,
          padding: "24px",
          letterSpacing: "3px",
          lineHeight: "1.3em",
          textAlign: "center",
        }}
        className="gsapFooterLogo"
      >
        KALPATARU INTERIORS
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: "1200px",
          marginTop: "30px",
        }}
        className="FooterContainer"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800 }}>SERVICES</span>
          <span
            style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
            className="RouteOptions2"
            onClick={handleContactClick}
          >
            Call Us
          </span>
          <span
            onClick={handleContactClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            +91 7700096022
          </span>
          <span
            onClick={handleContactClick2}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            +91 8087678869
          </span>
          <span
            style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
            className="RouteOptions2"
            onClick={handleWhatsAppClick}
          >
            Whatsapp Us
          </span>
          <span
            onClick={handleWhatsAppClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            +91 7700096022
          </span>
          <span
            style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
            className="RouteOptions2"
            onClick={handleEmailClick}
          >
            Email Us
          </span>
          <span
            onClick={handleEmailClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            kalpataruinteriors2229@gmail.com
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800 }}>
            EXPLORE MORE
          </span>
          <a
            href="#Portfolio"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              portfolio
            </span>
          </a>
          <a
            href="#Process"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Our Process
            </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Services
            </span>
          </a>
          <a
            href="#Clients"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Clients
            </span>
          </a>
          <a
            href="#Gallery"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Gallery
            </span>
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800, color: "black" }}>
            FOLLOW US ON
          </span>
          <div>
            <a href="https://www.facebook.com/share/DuXMKLxGTtTj7Vt2/?mibextid=qi2Omg">
              <FacebookIcon
                style={{
                  color: "black",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://www.instagram.com/kalpataru_interiors/">
              <InstagramIcon
                style={{
                  color: "black",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
            </a>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="FooterSection"
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: 800,
              margin: "0",
              textTransform: "uppercase",
            }}
          >
            KEY Personnel
          </span>
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            Supriya Thule Surywanshi
          </span>
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            Tejashree Gaikwad 
          </span>
        </div>
      </div>
      <h6
        style={{
          textAlign: "center",
          position: "relative",
          top: "95px",
          color: "gray",
          fontWeight: 200,
          fontSize: "12px",
        }}
      >
        2024 © Kalpataru interiors All rights reserved.
      </h6>
    </div>
  );
};

export default Footer;
