import React from "react";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import HomePage from "./Components/Homepage.tsx";

function App() {
  const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }, [history]);

    return null;
  };

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
            <Route exact path="/" component={HomePage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
