import React, { useEffect, useState } from "react";
import { service1, service2, service3 } from "../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Services: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
    gsap.from(".serviceGSAP", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".serviceGSAP",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".serviceTextGSAP1", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".serviceTextGSAP1",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".serviceTextGSAP2", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".serviceTextGSAP2",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".serviceTextGSAP3", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".serviceTextGSAP3",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.fromTo(".serviceImgGSAP", 
      { scale: 0, autoAlpha: 0 },
      {
        duration: 2,
        scale: 1,
        autoAlpha: 1,
        ease: 'power2.out',
      scrollTrigger: {
        trigger: ".serviceImgGSAP",
        scroller: "body",
        markers: false,
        start: "top 70%",
      },
    });
    gsap.fromTo(".serviceImgGSAP1", 
      { scale: 0, autoAlpha: 0 },
      {
        duration: 1.5,
        scale: 1,
        autoAlpha: 1,
        ease: 'power2.out',
      scrollTrigger: {
        trigger: ".serviceImgGSAP1",
        scroller: "body",
        markers: false,
        start: "top 70%",
      },
    });
    gsap.fromTo(".serviceImgGSAP2", 
      { scale: 0, autoAlpha: 0 },
      {
        duration: 1.5,
        scale: 1,
        autoAlpha: 1,
        ease: 'power2.out',
      scrollTrigger: {
        trigger: ".serviceImgGSAP2",
        scroller: "body",
        markers: false,
        start: "top 70%",
      },
    });
  }
  });

  return (
    <div id="services" style={{ width: "100%" }}>
      <h1
        style={{
          fontSize: "45px",
          color: "#A87C36",
          fontWeight: 300,
          textAlign: "center",
          marginBottom: "30px"
        }}
        className="serviceGSAP"
      >
        <span style={{ color: "#1b1b1b" }}>OUR</span> SERVICES
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 80px",
        }}
        className="ServiceSections"
      >
        <img
          className="serviceImage serviceImgGSAP"
          src={service1}
          alt=""
          style={{ height: "500px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "50px",
          }}
          className="textDiv serviceTextGSAP1"
        >
          <h4 className="serviceHeading" style={{textTransform:"capitalize"}}>
            What services do Kalpataru Interior certified interior designers
            offer?
          </h4>
          <ul style={{fontWeight:500 }}>
            <li>Schematic design and development</li>
            <li>Space planning</li>
            <li>Complete interior design specifications</li>
            <li>Working drawings</li>
            <li>Project monitoring and post-occupancy review</li>
          </ul>
          <p style={{ width: "90%", textAlign: "justify",fontWeight:500  }}>
            In providing these and related services, certified interior
            designers ensure that all fire codes, flammability ratings, and
            other safety and product issues are addressed.
          </p>
        </div>
      </div>

      <div
        className="ServiceSections"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 80px",
          flexDirection: "row-reverse",
          marginTop: "50px",
        }}
      >
        <img
          className="serviceImage serviceImgGSAP1"
          src={service2}
          alt=""
          style={{ height: "500px", width: "753px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "50px",
          }}
          className="textDiv serviceTextGSAP2"
        >
          <h4 className="serviceHeading">
            What credentials does a Kalpataru Interior Certified Interior
            Designer have?
          </h4>
          <ul style={{fontWeight:500 }}>
            <li>A description of the designer's qualifications;</li>
            <li>Names of former clients as references;</li>
            <li>
              A clear and complete description of the work that will be done and
              the products that will be delivered;
            </li>
            <li>A project schedule, including terms of payment; and,</li>
          </ul>
          <p style={{ width: "90%", textAlign: "justify",fontWeight:500  }}>
            A Licensed Kalpataru Interior CID has earned at least 7 years of
            education/experience credit and has passed a 12.5-hour national
            licensing examination. With the exception of those who applied for
            licensure prior to January 1, 1996, certified interior designers
            have at least a two-year associate's degree from an interior design
            program registered by the State Education Department (or its
            equivalent) and have earned qualifying experience as interior
            designers.
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "40px 80px",
        }}
        className="ServiceSections"
      >
        <img
          src={service3}
          style={{ height: "500px", width: "753px" }}
          className="serviceImage serviceImgGSAP2"
          alt="service-image"
        />

        <div style={{ margin: "0 0 0 50px " }} className="textDiv serviceTextGSAP3">
          <h3 className="serviceHeading">Certified Interior Designers and Their Services</h3>
          <p style={{ textAlign: "justify",fontWeight:500  }}>
            A Certified Interior Designer (CID) licensed in Kalpataru Interior
            plans, designs, supervises and/or consults on various aspects of
            interior spaces including:
          </p>
          <ul style={{fontWeight:500 }}>
            <li>Layouts and planning of interior construction</li>
            <li>Furnishings and fixtures</li>
            <li>Cabinetry, lighting and finishes</li>
            <li>
              Other aspects of interior construction not materially related to
              the main structural building components or systems
            </li>
            <li>
              Final plans, specifications and reports that contain the signature
              of the certified interior designer.
            </li>
          </ul>
          <p style={{ textAlign: "justify",fontWeight:500  }}>
            To safeguard people and property, certified interior designers
            incorporate construction, fire, safety and accessibility codes and
            data into their design services.
          </p>
          <p style={{ textAlign: "justify",fontWeight:500 }}>
            You might employ a CID to plan and design the interior space of a
            new or existing house or business. The CID will consider your needs
            as well as environmental safety laws, building and fire codes,
            accessible-design regulations, and historic preservation factors.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
