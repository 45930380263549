import React, { useEffect, useState } from "react";
import {
  Apartment1,
  Apartment2,
  Apartment3,
  Apartment4,
  Rooms1,
  Rooms2,
  Rooms3,
  Rooms4,
  service4,
  service5,
  service6,
  service7,
} from "../Assets.tsx";
import Navbar from "./Navbar.tsx";
import Footer from "./Footer.tsx";
import { Box, Tab, Tabs } from "@mui/material";
import Carousel from "../Common-Compnents/Carousel.tsx";
import "./HomePage.css";
import Services from "./Services.tsx";
import HistoryCarousel from "../Common-Compnents/HistoryCarousel.tsx";
import Cards from "../Common-Compnents/Cards.tsx";
import BannerCarousel from "../Common-Compnents/BannerCarousel.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Gallery from "./Gallery/Gallery.tsx";
gsap.registerPlugin(ScrollTrigger);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Homepage = () => {
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
    gsap.from(".galleryGSAP", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".galleryGSAP",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".rooms-suits-description", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".rooms-suits-description",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
  }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const apartmentImages = [Apartment1, Apartment2, Apartment3, Apartment4];
  const roomImages = [Rooms1, Rooms2, Rooms3, Rooms4];

  const ourHistory = [
    {
      image:Apartment1,
      name: "Jambhulwadi site",
    },
    {
      image:
      Apartment2, 
      name: "Swapnil House",
    },
    {
      image:
      Apartment3,
      name: "Lohgaon Site",
    },
    {
      image:
      Apartment4, 
      name: "Lohgaon Site",
    },
  ];

  const OurService = [ 
    {
      image: service4,
      number: "01",
      heading: "Understand Client Requirements",
      description:
        "Our first step when we take up interior design is learning about your family, the kind of things you like, your budget, your goals, and the way you like things in your house.",
    },
    {
      image: service5,
      number: "02",
      heading: "Concept Development",
      description:
        "During the conceptual development phase, clients get to see close-ups of real designs, which include simple elevations, furniture placements, and other details of your design.",
    },
    {
      image: service6,
      number: "03",
      heading: "Production",
      description:
        "Once the designs are approved, they are sent to our state-of-the-art manufacturing facility for production. The furnishings are manufactured with the highest quality materials.",
    },
    {
      image: service7,
      number: "04",
      heading: "Executions at the site",
      description:
        "The designer and our project management team will make regular site visits to coordinate progress and make sure installations and finishing are in their proper sequence.",
    },
  ];

  return (
    <div>
      <Navbar />
      <BannerCarousel />
      <div id="Portfolio" className="rooms-suits-container">
        <div className="rooms-suits-header galleryGSAP">PORTFOLIO</div>
        <div className="rooms-suits-content">
          <div className="rooms-suits-description">
            <p>
              We are thrilled to be a part of Design Share - a group of the Top
              10 NYC Interior Design Firms that gather each month to share tips,
              challenges, and offer resources and support to each other. If you
              have recently purchased an apartment in one of the luxurious
              buildings in Manhattan, the interiors have to be just as
              awe-inspiring. Whether you want a traditional or modern aesthetic,
              an expert interior designer can help in bringing your dream
              apartment to life. Our group is comprised of many design firms
              with different tastes and styles, and it's important to choose a
              designer whose work resonated with your personal aesthetic
            </p>
          </div>
        </div>
        <div className="rooms-suits-tabs">
          <Box sx={{ width: "100%"}}>
            <Box sx={{ borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="RESIDENTIAL"
                  {...a11yProps(0)}
                  sx={{ color: "#211551 !important", borderColor: "black" }}
                />
                <Tab
                  label="COMMERCIAL"
                  {...a11yProps(1)}
                  sx={{ color: "#211551 !important" }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Carousel images={apartmentImages} />
              <div className="tab-description">
                House WITH INTERIOR DESIGN
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Carousel images={roomImages} />
              <div className="tab-description">Office WITH INTERIOR</div>
            </CustomTabPanel>
          </Box>
        </div>
      </div>
      <Services />
      <Cards cards={OurService} />

      <HistoryCarousel members={ourHistory} />
      <Gallery />
      <Footer />
    </div>
  );
};

export default Homepage;
