import React from "react";
import { Carousel } from "react-bootstrap";
import { BannerVideo, BannerVideo2, Banner5, Banner, Banner2, Banner3 } from "../Assets.tsx";
import "./BannerCarousel.css";

const BannerCarousel = () => {
  return (
    <Carousel fade={true} interval={3000}>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
         <video className="d-block w-100" autoPlay loop muted>
          <source src={BannerVideo} type="video/mp4" />
        </video>
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <h1>KALPATARU_INTERIORS</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
         <video className="d-block w-100" autoPlay loop muted>
          <source src={BannerVideo2} type="video/mp4" />
        </video>
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <h1>KALPATARU_INTERIORS</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img className="d-block w-100" src={Banner2} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <h1>KALPATARU_INTERIORS</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img className="d-block w-100" src={Banner} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        ></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img className="d-block w-100" src={Banner5} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <h1 style={{ color: "Black", marginBottom: "15px" }}>
            KALPATARU_INTERIORS
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img className="d-block w-100" src={Banner3} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <h1>KALPATARU_INTERIORS</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default BannerCarousel;
